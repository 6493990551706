export interface Coupon {
  id: string,
  active: boolean,
  currency: string,
  amount?: number,
  percentage?: number
}

export interface CheckCouponRequest {
  customerCPF: string,
  couponId: string
}

export interface SelfCheckCouponRequest {
  couponId: string
}

export interface CheckCouponResponse {
  couponAlreadyReceived: boolean,
  coupon: Coupon | null
}

export default {
  async checkCoupon(request: CheckCouponRequest): Promise<CheckCouponResponse> {
    return await apiPost(`billing/coupon/check`, { body: request })
  },
  async selfCheckCoupon(request: SelfCheckCouponRequest): Promise<CheckCouponResponse> {
    return await apiPost(`self/billing/coupon/check`, { body: request })
  },
}
