<template lang="pug">
.migrate-subscription
  h1 Alterar plano de assinatura

  .description
    p
      | A sua assinatura atual é do &#32;
      b Plano {{ currentPlan }}
      | &#32;pelo valor de &#32;
      b {{ currentPlanInstallments}} R${{ centsToString(currentPlanPrice) }}/mês + frete de R${{ centsToString(shippingRate) }}.

    p Escolha seu novo plano:

  .select-plan

    .plans
      .plan(
        style="background-color: #7b61ff",
        :class="{ selected: state.plan =='annually' }",
        @click="selectPlan('annually')"
      )
        .title Anual
        .price
          | 12x
          br
          span.value R$&nbsp;149,90/mês
          br
          |+&nbsp;frete

      .plan(
        style="background-color: #65c9e0",
        :class="{ selected: state.plan =='biannually' }",
        @click="selectPlan('biannually')"
      )
        .title Semestral
        .price
          | 6x
          br
          span.value R$&nbsp;159,90/mês
          br
          |+&nbsp;frete

    .recurrence-notice Todos os planos são renovados automaticamente

  .checkout-summary
    table
      tbody
        tr
          td.left Box Magenta
          td.right(v-if="monthlyPrice != undefined")
            span(v-if="installments && installments > 1") {{ installments }}x&#32;
            | R$&nbsp;{{ centsToString(monthlyPrice) }}
          td.right(v-else) R$&nbsp;--,--

        tr
          td.left Frete
          td.right(v-if="shippingRate != undefined")
            span(v-if="shippingRate > 0 && installments && installments > 1") {{ installments }}x&#32;
            span(v-if="shippingRate > 0") R$&nbsp;{{ centsToString(shippingRate) }}
            span.bold.magenta(v-if="shippingRate == 0") GRÁTIS
          td.right(v-else) R$&nbsp;--,--

        tr(v-if="couponValue != undefined")
          td.left.magenta Cupom {{ discountCoupon?.id?.toUpperCase() }}
          td.right.bold.magenta
            | -R$&nbsp;{{ centsToString(couponValue) }}

    hr

    table(v-if="couponValue == undefined")
      tbody
        tr
          td.left Total
          td.right(v-if="total != undefined")
            span(v-if="installments && installments > 1") {{ installments }}x&#32;
            | R$&nbsp;{{ centsToString(total) }}
          td.right(v-else) R$&nbsp;--,--

    table(v-else)
      tbody
        tr
          td.left
            span(v-if="discountParts && discountParts > 1") Primeiros {{ discountParts }} meses
            span(v-else) Primeiro mês

          td.right(v-if="total != undefined")
            span(v-if="discountParts && discountParts > 1") {{ discountParts }}x&#32;
            | R$&nbsp;{{ centsToString(total - (couponValue / (discountParts ?? 1.0) )) }}

          td.right(v-else) R$&nbsp;--,--
        tr
          td.left Próximos meses
          td.right(v-if="total != undefined")
            | R$&nbsp;{{ centsToString(total) }}
          td.right(v-else) R$&nbsp;--,--

    .recurrence-notice * Todos os planos são renovados automaticamente

    .coupon
      form(@submit.prevent="applyCoupon(state.couponCode)")
        TextInput(
          label="Cupom",
          v-model.trim="state.couponCode"
        )

        button(v-show="false", type="submit")

        Button(:loading="loadingCoupon", label="Aplicar", @click="applyCoupon(state.couponCode)", rounded)

  .notice

    .next-renewal-date(v-if="nextRenewalDate")
      | A primeira cobrança em seu novo plano será feita no&#32;
      b dia&nbsp;{{ nextRenewalDate }}.

    | Ao assinar, você concorda com a nossa&#32;
    NuxtLink(:to="{ name: 'privacy-policy' }", target="_blank") política de privacidade&#32;
    | e nosso&#32;
    NuxtLink(:to="{ name: 'subscription-contract' }", target="_blank") contrato de assinatura.

  .actions
    Button(
      label="Alterar plano"
      @click="openConfirmDialog"
      :loading="confirmDialogOpen"
      size="large"
      rounded
    )

    Dialog(title="Alterar plano", v-model:open="confirmDialogOpen", width="640px")
      template(v-slot)
        p Tem certeza de que deseja alterar o plano de assinatura?

      template(v-slot:footer="{ close }")
        .confirm-dialog-actions(style="text-align: right")
          Button(label="Cancelar", plain, size="small", @click="close")
          Button(label="Alterar plano", size="small", @click="migrateSubscription", :loading="migrating")
</template>
<script setup lang="ts">
import type { FetchError } from 'ofetch'

import type { SelfCheckCouponRequest, Coupon } from '~/services/subscription/coupon/couponService'

import { useActiveSubscription } from '~/store/subscription/subscription'
import { planAliasToId, planBasePrice, planInstallments, planName, planBillingParts } from '~/services/subscription/subscriptionService'
import PancakeService from '~/services/pancake/pancakeService'

import CouponService from '~/services/subscription/coupon/couponService'

const dayjs = useDayjs()

useHead({
  title: 'Alterar plano de assinatura'
})

definePageMeta({
  requiresAuth: true,
  breadcrumb: [
    {
      title: 'Minha conta',
      link: { name: 'dashboard' },
    },
    {
      title: 'Alterar plano',
      link: { name: 'migrate-subscription' },
    },
  ],
})

const supportedPlans = [
  'br.com.magenta.vindi.test.basic.monthly.11990',
  'br.com.magenta.vindi.basic.monthly.11990',
  'br.com.magenta.vindi.test.basic.monthly.15990',
  'br.com.magenta.vindi.basic.monthly.15990',
  'br.com.magenta.vindi.test.basic.monthly.16990',
  'br.com.magenta.vindi.basic.monthly.16990',
  'br.com.magenta.vindi.test.basic.quarterly.34470',
  'br.com.magenta.vindi.basic.quarterly.34470',
  'br.com.magenta.vindi.test.basic.quarterly.46770',
  'br.com.magenta.vindi.basic.quarterly.46770',
]

const activeSubscription = useActiveSubscription()
await activeSubscription.refresh()

const notifications = useNotifications()

const state = reactive({
  plan: null as string | null,
  couponCode: ''
})

const loadingCoupon = ref(false)
const discountCoupon = ref<Coupon | null>(null)
const discountCouponId = ref<string | null>(null)

const confirmDialogOpen = ref(false)
const migrating = ref(false)
const showValidationErrors = ref(false)

const currentSubscription = computed(() => activeSubscription.subscription)
const currentPlan = computed(() => {
  if (activeSubscription.subscription) {
    return planName[activeSubscription.subscription.planId]
  }

  return ''
})
const currentPlanPrice = computed(() => {
  if (activeSubscription.subscription) {
    return planBasePrice[activeSubscription.subscription.planId]
  }

  return 0
})

const currentPlanInstallments = computed(() => {
  if (activeSubscription.subscription) {
    const installments = planInstallments[activeSubscription.subscription.planId]

    if (installments > 1) {
      return `${installments}x de `
    }
  }

  return ''
})

const nextRenewalDate = computed(() => {
  if (activeSubscription.subscription && activeSubscription.subscription.nextRenewalAt) {
    return dayjs(activeSubscription.subscription.nextRenewalAt).format('DD/MM/YYYY')
  }

  return null
})

const shippingRate = computed(() => {
  if (currentSubscription.value) {
    return currentSubscription.value.shippingCost
  }

  return 0
})

const selectedPlanId = computed(() => {
  if (state.plan) {
    return planAliasToId(state.plan, discountCoupon.value != null)
  }

  return undefined
})

const monthlyPrice = computed(() => {
  if (selectedPlanId.value) {
    return planBasePrice[selectedPlanId.value]
  }

  return undefined
})

const installments = computed(() => {
  if (selectedPlanId.value) {
    return planInstallments[selectedPlanId.value]
  }

  return undefined
})

const discountParts = computed(() => {
  if (selectedPlanId.value !== undefined) {
    return planBillingParts[selectedPlanId.value] ?? undefined
  } else {
    return undefined
  }
})

const couponValue = computed(() => {
  if (discountCoupon.value) {
    if (discountCoupon.value.amount) {
      return discountCoupon.value.amount
    } else if (discountCoupon.value.percentage && monthlyPrice.value) {
      return discountCoupon.value.percentage * monthlyPrice.value
    } else {
      return undefined
    }
  } else {
    return undefined
  }
})

const total = computed(() => {
  if (monthlyPrice.value) {
    return monthlyPrice.value + shippingRate.value
  }

  return undefined
})

const selectPlan = (plan: string) => {
  state.plan = plan
}

const applyCoupon = async (couponText: string) => {
  const coupon = couponText.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()

  loadingCoupon.value = true

  try {
    const request: SelfCheckCouponRequest = {
      couponId: coupon,
    }

    const response = await CouponService.selfCheckCoupon(request)

    if (response.couponAlreadyReceived) {
      notifications.error(`Cupom ${coupon.toUpperCase()} válido apenas para novos assinantes`)
    } else if (!response.coupon) {
      notifications.error(`Cupom ${coupon.toUpperCase()} não disponível`)
    } else {
      discountCoupon.value = response.coupon
      discountCouponId.value = response.coupon.id

      state.couponCode = ""

      notifications.success(`Cupom ${coupon.toUpperCase()} aplicado`)
    }
  } catch (e) {
    captureException(new Error('Failed to apply coupon', { cause: e }))

    notifications.error("Não foi possível aplicar o cupom. Por favor, tente novamente mais tarde ou entre em contato com o nosso atendimento.")
  } finally {
    loadingCoupon.value = false
  }
}

const openConfirmDialog = () => {
  if (!selectedPlanId.value) {
    showValidationErrors.value = true
    notifications.warning('Por favor, selecione um plano para continuar.')

    return
  }

  showValidationErrors.value = false
  confirmDialogOpen.value = true
}

const migrateSubscription = async () => {
  migrating.value = true

  try {
    const request = {
      planId: selectedPlanId.value!,
      couponId: discountCouponId.value,
    }

    await PancakeService.migrate(currentSubscription.value!.id, request)

    notifications.success("O seu plano de assinatura foi alterado com sucesso!")

    await activeSubscription.refresh()

    navigateTo({ name: 'dashboard' }, { replace: true })
  } catch (e: FetchError | any) {
    captureException(new Error('Failed to migrate subscription', { cause: e }))

    notifications.error("Não foi possível alterar o seu plano de assinatura. Por favor, tente novamente mais tarde ou entre em contato com o nosso atendimento.")
  } finally {
    migrating.value = false
  }
}

if (currentSubscription.value) {
  if (!supportedPlans.includes(currentSubscription.value.planId)) {
    notifications.error('O seu plano de assinatura não pode ser migrado. Por favor, entre em contato com o nosso atendimento.')
    navigateTo({ name: 'dashboard' }, { replace: true })
  }
}
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables'

.migrate-subscription
  position: relative
  width: 100%
  max-width: 708px
  margin: 0 auto
  padding: 0 14px
  text-align: left
  animation: fade-in .2s forwards

  p
    b
      color: $magenta

  .select-plan
    margin: 32px 0

    .no-plan-selected-warning
      font-weight: bold
      color: $acquaBlue
      text-align: center

    .plans
      display: flex
      flex-direction: column
      justify-content: flex-start
      align-items: stretch
      gap: 16px

      @include breakpoint(tablet)
        flex-direction: row
        justify-content: space-evenly

      .plan
        position: relative
        display: grid
        grid-template-areas: "title price" "title price"
        gap: 8px
        cursor: pointer
        padding: 16px
        border-radius: 8px
        color: white
        text-align: center
        font-family: Spartan, sans-serif
        flex-grow: 0
        font-size: 14px

        &.selected::before
          content: ''
          position: absolute
          top: -5px
          left: -5px
          width: calc(100% + 4px)
          height: calc(100% + 4px)
          border-radius: 12px
          border: 3px solid #EE3D8A

        .title
          display: flex
          flex-direction: column
          justify-content: center
          grid-area: title
          font-weight: bold
          font-size: 18px

        .price
          grid-area: price

          span.value
            font-size: 18px
            font-weight: 800


        @include breakpoint(mobileonly)
          font-size: 16px

          .title
            font-size: 22px

        @include breakpoint(tablet)
          grid-template-areas: "title" "price"
          grid-template-columns: 1fr

    .recurrence-notice
      margin-top: 16px
      text-align: center
      font-size: 14px
      font-weight: bold

  .checkout-summary
    max-width: 480px
    margin: 32px auto
    border-radius: 8px
    background-color: $beige
    padding: 16px

    .bold
      font-weight: bold

    .magenta
      color: $magenta

    .recurrence-notice
      margin-top: 16px
      font-size: 12px

    .coupon
      margin-top: 32px

      .text-input
        input
          height: 10px
          border-color: black !important

    table
      width: 100%
      line-height: 28px
      border-collapse: collapse

      .left
        font-weight: bold

      .right
        text-align: right

      .lineThrough
        text-decoration: line-through

      &.total
        margin-top: 8px
        padding-top: 8px
        border-top: 1px solid black
        font-weight: bold

      &.subscription-gift
        position: relative
        background-color: $acquaBlue
        color: white
        border-radius: 4px
        line-height: 16px
        margin-top: 16px
        white-space: pre-line

        &::before
          position: absolute
          display: block
          top: 2px
          left: 2px
          width: calc(100% - 4px)
          height: calc(100% - 4px)
          border: dashed 1px white
          border-radius: 4px
          content: ''
          box-sizing: border-box

        td
          padding: 12px

        .right
          font-size: 14px

  .notice
    font-size: 14px
    text-align: center

    a, b
      color: $magenta
      font-weight: bold

    .next-renewal-date
      margin-bottom: 8px
      font-size: 16px
      font-weight: bold

  .actions
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    height: 64px
    margin: 16px 0

    .loading
      height: 50px

    .large
      text-align: center
      font-size: 24px
      width: 220px
</style>
<style lang="sass">
@import '~/assets/styles/variables'

.migrate-subscription
  .checkout-summary
    .coupon
      position: relative

      .button
        position: absolute
        bottom: 16px
        right: 0
        margin: 4px 0
        padding: 2px 8px
        height: 32px
        font-size: 14px
        font-weight: 800

      .text-input
        input
          font-size: 14px
          height: 28px
          border-bottom: 1px solid black
          padding: 8px 2px

          &:focus
            border-color: $magenta
</style>
